<template>
  <div class="pa-5">

    <v-text-field
        filled
        label="email"
        v-model="recordObject.email"
        autocomplete="new-password"
        placeholder="user@domain.com"
        :rules="emailRules"
    />
    <v-text-field
        filled
        autocomplete="new-password"
        label="mot de passe"
        v-model="recordObject.clearPwd"
        type="password"
        :rules="passwordRules"
    />

    <v-row>
      <v-col cols="6" class="py-0" >
      <v-switch
          :disabled="recordObject.isTheCurrentUser"
          v-model="recordObject.isadmin"
          label="Peut modifier des données"
      />
      </v-col>
    </v-row>

    <v-row v-if="recordObject.isadmin">
      <v-col cols="12">
        <div class="body-2">Droits</div>
      </v-col>

      <!--
      <v-col cols="6" class="py-0">
        <v-switch
            v-model="recordObject.canManageForms"
            label="Peut gérer les formulaires"/>
      </v-col>
      -->
      <v-col  cols="6" class="py-0">
        <v-switch
            v-model="recordObject.canEditContent"
            label="Peut éditer le contenu"/>
      </v-col>
      <v-col  cols="6" class="py-0">
        <v-switch
            :disabled="!recordObject.canEditContent"
            v-model="recordObject.canEditLayout"
            label="Peut éditer le layout"/>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-switch
            :disabled="!recordObject.canEditContent"
            v-model="recordObject.canEditRedirections"
            label="Peut gérer les redirections"/>
      </v-col>
      <v-col  cols="6" class="py-0">
        <v-switch
            :disabled="!recordObject.canEditContent"
            v-model="recordObject.canManageUsers"
            color="error"
            label="Peut gérer les utilisateurs"/>
      </v-col>
      <v-col cols="6" class="py-0" >
        <v-switch
            :disabled="!recordObject.canEditContent"
            v-model="recordObject.isdev"
            color="error"
            label="Afficher les options développeur"
        />
      </v-col>

    </v-row>






    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import UserModel from "@/Models/UserModel";

export default {
  name: "user-edit",
  components: { CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {UserModel}
     */
    recordObject(){
      return this.record;
    },
    passwordRules(){
      return UserModel.passwordRules();
    },
    emailRules(){
      return UserModel.emailRules();
    }
  }
}
</script>

