<template>
<div class="mb-5">

  <v-tabs
      height="60"
      v-model="tab"
      background-color="#eee"
      class="admin-tabs">
    <v-tab href="#meta" >
      <v-badge
          color="error"
          :value="recordObject.errorsMeta.length>0"
          :content="recordObject.errorsMeta.length"
      >Méta</v-badge>

    </v-tab>
    <v-tab href="#poster">Poster</v-tab>
    <v-tab v-if="blocks" href="#blocks">blocks</v-tab>
    <v-tab href="#previews" v-if="hasPreviews">Previews</v-tab>
    <v-tab href="#seo" v-if="recordObject.pageUrl && recordObject.id">
      <v-badge
          color="error"
          :value="recordObject.pageUrl.errors.length>0"
          :content="recordObject.pageUrl.errors.length"
      >SEO</v-badge>
    </v-tab>
    <v-tab href="#config" v-if="$db.userAdminDev">
      <v-icon small>{{ $icons.config }}</v-icon>
    </v-tab>

    <v-tabs-items v-model="tab">

      <!-- META -->
      <v-tab-item value="meta">
        <v-card flat>
          <v-card-text class="tab-content" v-if="tab==='meta'">
            <v-alert
                v-if="recordObject.errorsMeta.length"
                color="error" dark dense tile elevation="3">
              <div v-for="(err,i) of recordObject.errorsMeta" :key="i">{{err}}</div>
            </v-alert>

            <v-row>
              <v-col>
                <v-text-field
                    filled
                    :disabled="rec.preventAction.rename"
                    :append-icon="rec.preventAction.rename?$icons.lock:''"
                    :label="getLabel('name','Nom')"
                    :placeholder="getPlaceholder('name','Nom de la page ici...')"
                    hide-details
                    v-model="recordObject.name"/>
              </v-col>
              <v-col cols="3" v-if="record.id && !rec.preventAction.delete">
                <v-switch
                    v-model="recordObject.draft"
                    :label="recordObject.draft?'Est invisible !':'Est visible'"
                    hide-details
                    color="orange"
                />
              </v-col>
              <v-col cols="12" v-if="recordObject.id && !recordObject.preventAction.delete">
                <date-field
                    label="Date de publication"
                    :field="recordObject.date_published"
                />
              </v-col>
            </v-row>

            <!--
            <record-field
                :label="getLabel('parent','Page parente')"
                :placeholder="getLabel('parent','Choisissez')"
                :field="recordObject.parent"
                :record-types="['page']"/>
                -->
            <slot name="meta"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- POSTER -->
      <v-tab-item value="poster">
        <v-card flat>
          <v-card-text  class="tab-content" v-if="tab==='poster'">
            <image-field label="Image"
                         :field="recordObject.thumbnail"/>
            <br>
            <file-field label="Vidéo"
                        file-accept="video/*"
                        :field="recordObject.videoposter"/>

            <field-label class="mb-5">Texte associé au poster</field-label>
            <v-textarea
                filled auto-grow :rows="3"
                v-model="recordObject.textposter" />
            <slot name="poster"/>
          </v-card-text>
        </v-card>
      </v-tab-item>



      <!-- BLOCKS -->
      <v-tab-item
          value="blocks">
        <v-card flat>
          <v-card-text  class="tab-content" v-if="tab==='blocks'">
            <slot name="blocks"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- PREVIEWS -->
      <v-tab-item
          value="previews"
          v-if="hasPreviews">
        <v-card flat>
          <v-card-text class="tab-content" v-if="tab==='previews'">
            <slot name="previews"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- SEO -->
      <v-tab-item
          value="seo"
          v-if="recordObject.id">
        <v-card flat>
          <v-card-text class="tab-content" v-if="tab==='seo'">
            <page-url-fields :pageUrl="recordObject.pageUrl"/>
            <slot name="seo"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- CONFIG -->
      <v-tab-item
          value="config"
          v-if="recordObject.id && $db.userAdminDev">
        <v-card flat>
          <v-card-text class="tab-content" v-if="tab==='config'">
            <record-authorizations
                :record="record"/>
            <slot name="config"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </v-tabs>






</div>
</template>

<script>
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
//import RecordField from "@/ee09/db-ui/fields/record-field";
//import ImageField from "@/ee09/db-ui/fields/image-field";
import FileField from "@/ee09/db-ui/fields/file-field";
import PageUrlFields from "@/Models/edit-shared/page-url-fields";
import RecordAuthorizations from "@/Models/edit-shared/record-authorizations";
import DateField from "@/ee09/db-ui/fields/date-field";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import FieldLabel from "@/ee09/db-ui/fields/field-label";
import ImageField from "@/ee09/db-ui/fields/image-field";

export default {
  name: "page-edit-fields",
  components: {
    ImageField,
    FieldLabel,
    RichTextField,
    DateField,
    RecordAuthorizations,
    PageUrlFields,
    FileField,
    //ImageField,
    //RecordField
  },
  mixins:[RecordEditMixin],
  data(){
    return{
      tab:this.$dbUi.preferences.editRecordActiveTab
    }
  },
  watch:{
    tab(){
      this.$dbUi.preferences.editRecordActiveTab=this.tab;
    }
  },
  props:{
    /**
     * Pour désactiver les blocks ici
     */
    blocks:{
      type:Boolean,
      default:true,
    },
    labels:{
      type:Object
    }
  },
  computed:{
    hasPreviews(){
      return !!this.$slots['previews']
    },
  /**
   *
   * @return {PageModel}
   */
  recordObject(){
    return this.record;
  },
    labelName(){
      if(this.labels && this.labels.name && this.labels.name.label){
        return this.labels.name.label
      }else{
        return "Nom"
      }
    },

  },
  methods:{
    /**
     * @param {String} fieldName
     * @param {String} defaultLabel
     */
    getLabel(fieldName,defaultLabel){
      return this.getProp("label",fieldName,defaultLabel);
    },
    /**
     * @param {String} fieldName
     * @param {String} defaultLabel
     */
    getPlaceholder(fieldName,defaultLabel){
      return this.getProp("placeholder",fieldName,defaultLabel);
    },
    getProp(prop,fieldName,defaultVal){
      if(this.labels && this.labels[fieldName] && this.labels[fieldName][prop]){
        return this.labels[fieldName][prop]
      }else{
        return defaultVal;
      }
    },
  }
}
</script>
<style lang="less">
.admin-tabs{
  .v-tab{
    font-size: 11px;
    min-width: 60px;
  }
  .v-tabs-bar{
    padding-left: 20px;
  }
  .tab-content{
    padding:  24px;
  }

}
</style>
