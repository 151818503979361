<template>
  <div>
    <v-text-field
        label="Titre"
        v-model="recordObject.title"
        counter maxlength="65"
    />
    <v-text-field
        class="mb-5"
        label="clean slug"
        v-model="recordObject.cleanSlug"
        :hint="recordObject.slug" persistent-hint
    />
    <v-textarea
        label="Description"
      v-model="recordObject.description"
        filled
        auto-grow
        rows="1"
        counter
    />
    <record-field
        label="Page associée"
        :field="recordObject.page"
        :record-types="[]"
    />
    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import RecordField from "@/ee09/db-ui/fields/record-field";


export default {
  name: "pageurl-edit",
  components: {RecordField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  },computed:{
    /**
     *
     * @return {PageurlModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

