var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('field-label',[_vm._v("Texte")]),_c('quill-editor',{model:{value:(_vm.obj.legende),callback:function ($$v) {_vm.$set(_vm.obj, "legende", $$v)},expression:"obj.legende"}}),_c('br'),_c('list-item-images-field',{attrs:{"field":_vm.obj.logos,"label":"Logos"}}),_c('v-divider',{staticClass:"my-5"}),_c('v-select',{attrs:{"filled":"","label":"Type de média","items":[
          {
            text:'Iframe (Youtube, Vimeo, etc...)',
            value:'iframe',
          },
          {
            text:'Diaporama',
            value:'photos',
          }

      ]},model:{value:(_vm.obj.mediaType),callback:function ($$v) {_vm.$set(_vm.obj, "mediaType", $$v)},expression:"obj.mediaType"}}),(_vm.obj.mediaType==='iframe')?[_c('v-textarea',{staticStyle:{"font-family":"monospace","font-size":"11px","line-height":"1.5"},attrs:{"label":"iframe","filled":"","placeholder":"Adresse Vimeo, Youtube ou code <iframe/>","auto-grow":"","persistent-hint":_vm.error!=='',"error":_vm.error!=='',"error-messages":_vm.error},model:{value:(_vm.obj.userInput),callback:function ($$v) {_vm.$set(_vm.obj, "userInput", $$v)},expression:"obj.userInput"}}),(['youtube','vimeo'].indexOf(_vm.obj.provider)>-1)?[_c('h4',[_vm._v("Vidéo "+_vm._s(_vm.obj.provider))]),_c('v-switch',{attrs:{"label":"Autoplay"},model:{value:(_vm.obj.autoplay),callback:function ($$v) {_vm.$set(_vm.obj, "autoplay", $$v)},expression:"obj.autoplay"}}),_c('image-field',{attrs:{"field":_vm.obj.image,"label":"Miniature"}})]:_vm._e()]:_vm._e(),(_vm.obj.mediaType==='photos')?[_c('list-item-images-field',{attrs:{"field":_vm.obj.photos,"label":"Photos"}})]:_vm._e(),_c('v-divider',{staticClass:"my-5"}),_c('anchor-form',{attrs:{"block":_vm.obj}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }