<template>
<div class="">
  <!--photos-->

  <list-item-images-field
      :field="obj.photos"
      label="Photos"
  />




</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import MediaTextBlockEdit from "@/Models/blocks/media/media-text-block-edit";
import ListItemImagesField from "@/Models/blocks/edit/image-item-list-field";

export default {
  name: "diaporama-block-edit",
  components: {MediaTextBlockEdit,ListItemImagesField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {AccordionBlock}
     */
    obj(){
      return this.block;
    },
  }
}
</script>
