<template>
<div class="blocks-field">
  <field-label class="mb-5">{{label}}</field-label>

  <div>
    <add-block-buttons
        v-if="allowEditStructure"
        class="my-5" :field="fld" insert-top/>

    <template v-for="block of fld.blockList">
      <div v-if="block.blockModel"
           :is="displayBlocksNames?'named-block-layout':'div'" :block="block"
           :key="block.uid">
        <block-edit-box :block="block"
                        :field="fld"
                        :allow-edit-structure="allowEditStructure"
        />
      </div>

    </template>


  </div>

</div>
</template>

<script>
import FieldLabel from "@/ee09/db-ui/fields/field-label";
import AddBlockButtons from "@/ee09/db-ui/fields/blocks/add-block-buttons";
import BlockEditBox from "@/ee09/db-ui/fields/blocks/block-edit-box";
export default {
  name: "blocks-field",
  components: {BlockEditBox, AddBlockButtons, FieldLabel},
  props:{
    field:{
      type:Object
    },
    label:{
      type:String
    },
    /**
     * Permet de désactiver l' organisation et l' ajout de blocks
     **/
    allowEditStructure:{
      type:Boolean,
      default:true
    },
    /**
     * Permet d'afficher le nom du block
     **/
    displayBlocksNames:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    /**
     *
     * @return {BlocksField}
     */
    fld(){
      return this.field;
    }
  }
}
</script>

<style scoped>

</style>