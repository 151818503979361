<template>
<div class="image-field">
  <v-system-bar lights-out v-if="label">
    <field-label >{{label}}</field-label>
  </v-system-bar>

  <record-list-item
    v-if="readOnly"
    class="px-0"
    :record="field.record">
    <template v-slot:action>
      <slot/>
      <v-btn icon x-small @click="showDetails=!showDetails">
        <v-icon>{{$icons.config}}</v-icon>
      </v-btn>
    </template>
  </record-list-item>

  <v-autocomplete
    v-else
    v-model="field.record"
    :items="$db.recordListImages"
    :filter="customFilter"
    placeholder="rechercher une image"
    filled hide-details
    item-text="name"
    return-object

  >
    <template v-slot:prepend-inner>
      <!-- permet par exemple de placer un drapeau ;) -->
      <slot name="prepend"></slot>
    </template>
    <template v-slot:append>
      <slot name="action"></slot>
      <div @mousedown.stop="" v-if="uploader">
        <btn-dwd-file-field
            class="mr-2"
            :file-field="field"/>
        <record-create-btn
            small
            record-type="filerecord"
            file-accept="image/*"
            @set-record-file="setFile"
            :progress="progress"
            :status="status"
        />
      </div>
    </template>

    <!-- le record sélectionné -->
    <template v-slot:selection="data">
      <record-list-item :record="data.item" class="ml-n3"/>
    </template>

    <!-- Liste des fichiers -->
    <template v-slot:item="{item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <record-list-item-inner-div :record="item"/>
      </v-list-item>
    </template>
  </v-autocomplete>

  <v-row v-if="showDetails" class="mb-5">
    <v-col cols="12">
      <v-text-field
          label="Attribut ALT"
          :error="!field.alt"
          v-model="field.alt"
          hide-details
          dense
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
          :error="!field.title"
          label="Title"
          v-model="field.title"
          hide-details
          dense
      />
    </v-col>

    <v-col v-if="field.href" cols="12">
      <file-image-visualizer
          :image-field="field"
          :src="field.resize(1024,1024)"
      />
    </v-col>
  </v-row>
</div>
</template>

<script>
import RecordCreateBtn from "../records/record-create-btn";
import RecordListItem from "../records/record-list-item";
import FieldLabel from "../fields/field-label";
import FileImageVisualizer from "../components/file-image-visualizer";
import ImageField from "@/ee09/fields/ImageField";
import RecordListItemInnerDiv from "@/ee09/db-ui/records/record-list-item-inner-div";
import BtnDwdFileField from "@/ee09/db-ui/components/btn-dwd-file-field";

export default {
  name: "image-field",
  components: {
    BtnDwdFileField,
    RecordListItemInnerDiv,
    FileImageVisualizer,
    FieldLabel,
    RecordListItem,
    RecordCreateBtn,
  },
  mounted() {
    if(this.open){
      this.showDetails=true;
    }
  },
  props:{
    open:{
      type:Boolean,
      default:false
    },
    label:{
      type:String
    },
    field:{
      type:ImageField,
      validator(value){
        return value._title !== undefined
      }
    },
    uploader:{
      type:Boolean,
      default:true
    },
    /**
     * Si true alors il n'est pas possible de changer l'image (mais il est possible de changer alt, title et x,y)
     */
    readOnly:{
      type:Boolean
    }
  },
  data(){
    return{
      showDetails:false,
      progress:0,
      status:''
    }
  },
  methods:{
    /**
     *
     * @param {event} e
     * @param {FileRecord} record
     */
    setFile(e,record){
      let me = this;
      if(record){
        me.field.record=record;
      }
    },
    customFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1;
    }
  }
}
</script>
