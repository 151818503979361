<template>
<div class="">

  <!-- texte-->
  <field-label>Texte</field-label>
  <quill-editor  v-model="obj.legende" />
  <br>
  <!--logos-->
  <list-item-images-field
      :field="obj.logos"
      label="Logos"
  />

  <v-divider class="my-5"/>

  <v-select
      filled
      label="Type de média"
      v-model="obj.mediaType"
      :items="[
          {
            text:'Iframe (Youtube, Vimeo, etc...)',
            value:'iframe',
          },
          {
            text:'Diaporama',
            value:'photos',
          }

      ]"
  />

  <template v-if="obj.mediaType==='iframe'">
    <v-textarea
      style="font-family: monospace;font-size: 11px;line-height: 1.5;"
      label="iframe"
      filled
      v-model="obj.userInput"
      placeholder="Adresse Vimeo, Youtube ou code <iframe/>"
      auto-grow
      :persistent-hint="error!==''"
      :error="error!==''"
      :error-messages="error"
    />
    <template v-if="['youtube','vimeo'].indexOf(obj.provider)>-1">
      <h4>Vidéo {{obj.provider}}</h4>
      <v-switch v-model="obj.autoplay"
                label="Autoplay"
      />
      <image-field :field="obj.image" label="Miniature"/>
    </template>
  </template>

  <template v-if="obj.mediaType==='photos'">
    <!--photos-->
    <list-item-images-field
        :field="obj.photos"
        label="Photos"
    />
  </template>



  <v-divider class="my-5"/>

  <anchor-form :block="obj"/>


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import FieldLabel from "@/ee09/db-ui/fields/field-label";
import ImageField from "@/ee09/db-ui/fields/image-field";
import QuillEditor from "@/ee09/db-ui/fields/quill-editor";
import AnchorForm from "@/Models/blocks/edit/anchor-form";
import ListItemImagesField from "@/Models/blocks/edit/image-item-list-field";

export default {
  name: "media-text-block-edit",
  components: {
    ListItemImagesField,
    AnchorForm,
    QuillEditor,
     ImageField, FieldLabel},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MediaTextBlock}
     */
    obj(){
      return this.block;
    },
    error(){
      if(!this.obj.userInput){
        return "veuillez saisir un code iframe ou une url"
      }
      if(!this.obj.iframeSrc){
        return "Ce code ne semble pas valide"
      }
      return "";
    }
  }
}
</script>
