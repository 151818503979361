<template>
<div>
  <v-textarea
    label="Citation"
    v-model="obj.text"
    placeholder="Saisissez la citation"
     auto-grow
  />






</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";

export default {
  name: "poster-block-edit",
  components: {},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PosterBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
