<template>
<div class="btn-block-edit">

  <v-text-field
    v-model="obj.imdb"
    label="Lien IMDB"
    placeholder="https://..."
  />
  <v-text-field
    v-model="obj.unifrance"
    label="Lien UniFrance"
    placeholder="https://..."
  />
  <v-text-field
    v-model="obj.allocine"
    label="Lien AlloCine"
    placeholder="https://..."
  />
  <v-text-field
    v-model="obj.youtube"
    label="Lien YouTube"
    placeholder="https://..."
  />
  <v-text-field
    v-model="obj.vimeo"
    label="Lien Vimeo"
    placeholder="https://..."
  />


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";

export default {
  name: "icones-liens-block-edit",
  components: {},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {IconesLiensBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
