<template>
<div class="titre-block-edit">
  <v-textarea
    style="font-family: monospace;font-size: 11px;line-height: 1.5;"
    label="iframe"
    filled
    v-model="obj.userInput"
    placeholder="Adresse Vimeo, Youtube ou code <iframe/>"
    auto-grow
    :persistent-hint="error!==''"
    :error="error!==''"
    :error-messages="error"
  />


  <template v-if="['youtube','vimeo'].indexOf(obj.provider)>-1">
    <h4>Vidéo {{obj.provider}}</h4>
    <!-- si il n'y a pas d'image on propose l'autoplay -->
    <v-switch v-if="!obj.image.isOk"
              v-model="obj.autoplay"
              label="Autoplay"
    />
    <br>
    <image-field :field="obj.image" label="Miniature"/>
  </template>

  <br>
  <field-label>Légende</field-label>
  <rich-text-field v-model="obj.legende" />


  <field-label>Liens</field-label>
  <list-items-organizer :list="obj.links">
    <list-items-organizer-item
        v-for="link of obj.links.items"
        :list="obj.links"
        :item="link"
        :key="link.uid">
      <link-item-field :field="link"/>
    </list-items-organizer-item>
  </list-items-organizer>


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import FieldLabel from "@/ee09/db-ui/fields/field-label";
import ImageField from "@/ee09/db-ui/fields/image-field";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "iframe-block-edit",
  components: {LinkItemField, ListItemsOrganizerItem, ListItemsOrganizer, ImageField, FieldLabel, RichTextField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {IframeBlock}
     */
    obj(){
      return this.block;
    },
    error(){
      if(!this.obj.userInput){
        return "veuillez saisir un code iframe ou une url"
      }
      if(!this.obj.iframeSrc){
        return "Ce code ne semble pas valide"
      }
      return "";
    }
  }
}
</script>
