<template>
<div>
    <v-text-field
        label=""
        v-model="obj.text"
        placeholder="..."
    />
</div>
</template>

<script>
import BlockMixin from "../blocks/BlockMixin";

export default {
  name: "core-string-block-edit",
  components: {},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CoreStringBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
