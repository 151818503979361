<template>
  <div>

    <page-edit-fields
        :record="record"
        :labels="{name:{label:'Nom du projet',placeholder:'Mon super projet'}}"
    >
      <template slot="meta">
        <records-field
            placeholder="Formations associées"
            label=""
            mandatory
            :field="recordObject.relateds"
            :record-types="['formation']"/>
        <v-text-field
            filled
            label="Sous titre"
            :error="!recordObject.baseline"
            v-model="recordObject.baseline"
            placeholder="Complétez le titre du projet"/>
        <date-field
            label="Date du projet"
            :field="recordObject.date_published"/>
      </template>
      <template slot="blocks">
        <blocks-field
            label=""
            :field="recordObject.blocks"/>
      </template>

    </page-edit-fields>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import DateField from "@/ee09/db-ui/fields/date-field";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import RecordsField from "@/ee09/db-ui/fields/records-field";

export default {
  name: "projet-edit",
  components: {RecordsField, PageEditFields, BlocksField, DateField, CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {ProjetModel}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

