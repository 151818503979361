<template>
<div class="mb-4">
  <v-row>
    <v-col v-if="linkLabel">
      <field-label
          v-if="label"
          class="mb-2"
      >{{label}}</field-label>
      <v-text-field
          label="Texte affiché sur le lien"
          v-model="f.label"
      />
    </v-col>
    <v-col>
      <v-select
          class="mb-3"
          hide-details
          v-if="editableLink"
          label="Type de lien"
          v-model="f.linkType"
        :items="[
                {
                  text:'Page du site',
                  value:'page'
                },
                 {
                  text:'Lien externe',
                  value:'external'
                },
                 {
                  text:'Téléchargement',
                  value:'download'
                },


                ]"

      />
    </v-col>
  </v-row>


  <v-text-field
      v-if="f.external && editableLink"
      label="Url externe"
      v-model="f.url"
      hide-details
      :error-messages="f.errors"
  />
  <div v-if="!f.external && !f.isDownload">
    <record-field
        v-if="editableLink"
        label=""
        placeholder="Page en lien"
        :field="f.pageRecord"
        :record-types="[]"
    />
  </div>
  <div v-if="f.isDownload && editableLink">
    <file-field label=""
                placeholder="Fichier à télécharger"
                :field="f.fileField"
    />
  </div>
</div>
</template>

<script>
import FieldLabel from "./field-label";
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import RecordField from "@/ee09/db-ui/fields/record-field";
import FileField from "@/ee09/db-ui/fields/file-field";
export default {
  name: "link-item-field",
  mixins:[RecordFieldMixin],
  components: {
    FileField,
    RecordField,
    FieldLabel,
  },
  props:{
    linkLabel:{
      type:Boolean,
      default:true
    },
    editableLink:{
      type:Boolean,
      default:true
    }
  },
  data(){return{}},
  computed:{
    /**
     * Le champ LinkItem
     * @return {LinkItem}
     */
    f(){
      return this.field;
    },
  }
}
</script>
