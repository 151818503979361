<!-- bouton de téléchargement admin-->
<template>
  <v-icon
      v-if="fileField.record && fileField.record.isOk"
      @click="$utils.navigation.openBlank($api.dwd(fileField.record))"
  >
    {{$icons.download}}
  </v-icon>
</template>

<script>
import FileField from "@/ee09/fields/FileField";

export default {
  name: "btn-dwd-file-field",
  props:{
    fileField:{
      type:[FileField,Object]
    }
  }
}
</script>
