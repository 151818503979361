<template>
  <v-dialog v-model="linkModal" max-width="500px">
    <v-card>
      <v-tabs centered v-model="tab" class="mb-2">
        <v-tab :key="0">Url</v-tab>
        <v-tab :key="1">Page du site</v-tab>
        <v-tab :key="2">Fichier</v-tab>
      </v-tabs>
      <v-form @submit.prevent="applyLink">
        <v-card-text>



          <v-tabs-items v-model="tab">
            <v-tab-item :key="0">
              <v-text-field label="url" v-model="linkProps.url"/>
            </v-tab-item>
            <v-tab-item :key="1">
              <record-field
                  :field="searchRecord"
                  :record-types="[]"
                  label="Rechercher une page du site"/>
            </v-tab-item>
            <v-tab-item :key="2">
              <file-field
                  :field="searchFile"
                  :record-types="['filerecord']"
                  label="Rechercher un fichier"/>

            </v-tab-item>
          </v-tabs-items>

          <v-switch v-model="linkProps.blank"
                    label="ouvrir dans une nouvelle fenêtre"
          />
          <v-text-field label="Texte" v-model="linkProps.text"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text
                 color="grey"
                 @click="$emit('close')">
            Annuler

          </v-btn>
          <v-btn text
                 type="button"
                 color="primary"
                 @click="applyLink">
            Appliquer.
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import RecordField from "@/ee09/db-ui/fields/record-field";
import RecordFieldModel from "@/ee09/fields/RecordField";
import FileField from "@/ee09/db-ui/fields/file-field";
export default {
  name: "rich-text-link-modal",
  components: {FileField, RecordField},
  props:{
    linkProps:{
      type:Object,
      default:{
        text:"",
        url:"",
        blank:false,
        uid:null
      }
    }
  },
  data() {
    return {
      tab: null,
      linkModal:true,
      /**
       *@type {RecordField}
       **/
      searchRecord:null,
      searchFile:null,
      url:"",
      blank:false
    }
  },
  mounted() {
    this.searchRecord=new RecordFieldModel();
    this.searchFile=new RecordFieldModel();
  },
  methods:{
    applyLink(){
      this.$emit("setLink",this.linkProps)
    }
  },
  computed:{
    /**
     *@type {PageModel}
     **/
    searchRecordResult(){
      if(this.searchRecord){
        return this.searchRecord.record
      }
      return null;
    },
    /**
     *@type {FileRecord}
     **/
    searchFileResult(){
      if(this.searchFile){
        return this.searchFile.record
      }
      return null;
    }
  },
  watch: {
    searchRecordResult(){
      let me=this;
      if(this.searchRecordResult){
        this.linkProps.url=this.searchRecordResult.href;
        setTimeout(function(){
          //me.searchRecord.record=null;
        },200)

      }
    },
    searchFileResult(){
      let me=this;
      if(this.searchFileResult){
        this.linkProps.url=this.$api.dwd(this.searchFileResult,false);
        setTimeout(function(){
          //me.searchFileResult.record=null;
        },200)

      }
    }
  },
}
</script>

<style scoped>

</style>