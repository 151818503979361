<template>
  <div>
    <page-edit-fields
        :record="record"
        :blocks="false">

      <template v-slot:meta>
        <records-field
            placeholder="Pages de la liste"
            label=""
            mandatory
            :field="record.pages"
            :record-types="['page']"/>
      </template>

      <template v-slot:poster>
      </template>

      <!--
      <template slot="blocks">
        <blocks-field
            label=""
            :field="record.blocks"/>
      </template>
      -->

    </page-edit-fields>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
//import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import RecordsField from "@/ee09/db-ui/fields/records-field";


export default {
  name: "pagelist-edit",
  components: {
    RecordsField,
    PageEditFields,
    //BlocksField,
    CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  }
}
</script>

