var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('v-row',[(_vm.linkLabel)?_c('v-col',[(_vm.label)?_c('field-label',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('v-text-field',{attrs:{"label":"Texte affiché sur le lien"},model:{value:(_vm.f.label),callback:function ($$v) {_vm.$set(_vm.f, "label", $$v)},expression:"f.label"}})],1):_vm._e(),_c('v-col',[(_vm.editableLink)?_c('v-select',{staticClass:"mb-3",attrs:{"hide-details":"","label":"Type de lien","items":[
                {
                  text:'Page du site',
                  value:'page'
                },
                 {
                  text:'Lien externe',
                  value:'external'
                },
                 {
                  text:'Téléchargement',
                  value:'download'
                } ]},model:{value:(_vm.f.linkType),callback:function ($$v) {_vm.$set(_vm.f, "linkType", $$v)},expression:"f.linkType"}}):_vm._e()],1)],1),(_vm.f.external && _vm.editableLink)?_c('v-text-field',{attrs:{"label":"Url externe","hide-details":"","error-messages":_vm.f.errors},model:{value:(_vm.f.url),callback:function ($$v) {_vm.$set(_vm.f, "url", $$v)},expression:"f.url"}}):_vm._e(),(!_vm.f.external && !_vm.f.isDownload)?_c('div',[(_vm.editableLink)?_c('record-field',{attrs:{"label":"","placeholder":"Page en lien","field":_vm.f.pageRecord,"record-types":[]}}):_vm._e()],1):_vm._e(),(_vm.f.isDownload && _vm.editableLink)?_c('div',[_c('file-field',{attrs:{"label":"","placeholder":"Fichier à télécharger","field":_vm.f.fileField}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }