<template>
  <v-text-field
      type="datetime-local"
      filled
      :label="label"
      v-model="field.isoString"
      :hint="field.humanDisplay"
      persistent-hint
  >
    <template v-slot:prepend-inner v-if="field.isFuture">
        <v-icon size="24" color="orange" class="mr-2">{{$icons.clock}}</v-icon>
    </template>
  </v-text-field>
</template>

<script>
import DateField from "@/ee09/fields/DateField";

export default {
name: "date-field",
  props:{
    label:{
      type:String
    },
    field:{
      type:DateField
    }
  },

}
</script>

<style scoped>

</style>