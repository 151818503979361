<template>
  <div class="pa-5">
    <v-text-field
        filled
        label="Name"
        v-model="recordObject.name"
    />

    <v-row>
      <v-col md="6">
        <field-mime-type label="Type de fichier" :field="recordObject.mime"/>
      </v-col>

      <v-col md="6">
        <field-bytes label="Poids" :field="recordObject.bytesize"/>
      </v-col>

      <v-col md="6">
        <a :href="recordObject.href" target="_blank">
          <v-text-field
              readonly disabled
              label="Ouvrir le fichier"
              v-model="recordObject.href"
          />
        </a>
      </v-col>
      <v-col md="6">
        <v-text-field
            readonly disabled
            label="Signature MD5"
            v-model="recordObject.md5"
        />
      </v-col>

      <!-- preview image -->
      <v-col v-if="recordObject.isImage && recordObject.isOk" cols="12">
        <image-field
            open
            :field="recordObject.imagedata"
            label="Image"
        />
      </v-col>
    </v-row>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import FieldMimeType from "@/ee09/db-ui/fields/field-mime-type";
import FieldBytes from "@/ee09/db-ui/fields/field-bytes";
import ImageField from "@/ee09/db-ui/fields/image-field";


export default {
  name: "filerecord-edit",
  components: {
    ImageField,
    FieldBytes,
    FieldMimeType,
    CodePreview
  },
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  },computed:{
    /**
     *
     * @return {FileRecord}
     */
    recordObject(){
      return this.record;
    }
  }
}
</script>

