<template>
  <div :style="`position: relative;background-color: ${bgColor}`">
    <div class="ee09-bg-grid-photoshop pa-16">
      <v-img contain
             @load="applyXY"
             :id="'bounds-focal'+htmlid"
             :src="src"
            >
        <div v-if="f && f.isOk"
             class="focal-point elevation-10"
             :id="'focal'+htmlid"
        />
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="pink"
            ></v-progress-circular>



          </v-row>
        </template>
      </v-img>

      <v-app-bar absolute bottom color="transparent" elevation="0" dense>
        <v-spacer></v-spacer>
        <v-btn x-small
               v-for="hex of colors"
               :key="hex"
               :style="`--size:10px;${bgColor===hex?'border:2px solid blue;':''}`"
               elevation="5"
               class="ee09-bg-grid-photoshop ml-3"
               @click="bgColor=hex" :color="hex"
        >
        </v-btn>
      </v-app-bar>
    </div>
  </div>

</template>

<script>
require("../../css/ee09-bg-grid.less");
import RecordMixin from "../records/RecordMixin";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(Draggable);
export default {
  name: "file-image-visualizer",
  mixins:[RecordMixin],
  data(){
    return{
      bgColor:"#FFF",
      colors:['#0F0','#F00','#FFF','#000'],
      htmlid:this.$db.utils.string.uniqId("drag")
    }
  },
  props:{
    imageField:{
      type:Object
    },
    src:{
      type:String
    }
  },
  computed:{
    /**
     *
     * @return {ImageField}
     */
    f(){
      return this.imageField;
    }
  },
  mounted() {
    if(this.f && this.f.isOk){
      let me=this;
      Draggable.create('#focal'+this.htmlid,
          {
            bounds:'#bounds-focal'+this.htmlid,
            zIndexBoost:false,
            type:"x,y",
            edgeResistance:0.65,
            onDrag: function() {
              //me.saveXY();
            },
            onDragEnd:function() {
              me.saveXY();
            }
          }
      );
    }

  },
  methods:{
    applyXY(){
      let me=this;
      let el=document.getElementById('focal'+this.htmlid);
      let container=document.getElementById('bounds-focal'+this.htmlid);
      setTimeout(function(){
        gsap.to(el,{
          duration:1,
          x:container.clientWidth*me.f.x,
          y:container.clientHeight*me.f.y,
        })
      },1000)

    },

    saveXY(){
      let el=document.getElementById('focal'+this.htmlid);
      let container=document.getElementById('bounds-focal'+this.htmlid);
      let recEl=el.getBoundingClientRect();
      let recCont=container.getBoundingClientRect();
      let x=  (recEl.x - recCont.x) / (recCont.width);
      let y=  (recEl.y - recCont.y) / (recCont.height);
      this.f.x=Math.round( Math.max(Math.min(x,1),0) * 100 ) / 100;
      this.f.y=Math.round( Math.max(Math.min(y,1),0) * 100 ) / 100;
    }

  }

}
</script>
<style lang="less">
.focal-point{
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #45a0ff;
  z-index: 1;
  border-radius: 100%;
  top: -10px;
  left: -10px;
}
</style>
