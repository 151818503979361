<template>
<div>
  <v-switch
  label="Interdire la suppression"
  v-model="rec.extravars.preventDelete"
  />
  <v-switch
      label="Interdire de renommer"
      v-model="rec.extravars.preventRename"
  />
</div>
</template>

<script>
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";

export default {
  name: "record-authorizations",
  mixins:[RecordEditMixin],
}
</script>

<style scoped>

</style>