import BlockItem from "@/ee09/fields/BlockItem";

export default {
    props: {
        "block":{
            type:BlockItem,
            validate(){
                return true;
            }
        },
    },
    computed:{
        /**
         * Le block mais spécialement typé BlockItem pour l'autocomplétions de code
         * @return {BlockItem}
         */
        blc(){
            return this.block;
        }
    }
}