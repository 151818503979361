<template>
<div :id="'container-'+randId">
  <!--
  <div :id="randId+'tool'">

    <span class="ql-formats">
      <select class="ql-font"></select>
      <select class="ql-size"></select>
    </span>

    <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>

      <button class="ql-underline"></button>
      <button class="ql-strike"></button>

    </span>
    <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>

    <span class="ql-formats">
      <button class="ql-script" value="sub"></button>
      <button class="ql-script" value="super"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-header ty-title-section" value="1">Titre Fat</button>
      <button class="ql-header ty-title-paragraph" value="2">Titre Normal</button>
      <button class="ql-header ty-multiline-text" value="3">Titre Lecture</button>
      <button class="ql-header ty-rich-text-title" value="4">Titre Petit</button>
      <button class="ql-blockquote"></button>
      <button class="ql-code-block"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-direction" value="rtl"></button>
      <select class="ql-align"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-link"></button>

      <button class="ql-image"></button>
      <button class="ql-video"></button>
      <button class="ql-formula"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-clean"></button>
    </span>
  </div>
  -->
  <div :id="randId"></div>
  <template v-if="choosingLink">
    <rich-text-link-modal
        :link-props="linkProps"
        @close="choosingLink=false;"
        @setLink="setLink"
    />
  </template>

</div>
</template>

<script>
import RichTextLinkModal from "@/ee09/db-ui/fields/rich-text-link-modal";
import LinkItem from "@/ee09/models/LinkItem";
export default {
  name: "quill-editor",
  components: {RichTextLinkModal},
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      linkProps:{
        text:"",
        url:"",
        blank:false,
        uid:null
      },
      choosingLink:false,
      range:null,
      selection:null,
      selectionNode:null,
      quill:null,
      htmlCode:'',
      randId:$utils.string.uniqId("quill-")
    }
  },

  mounted() {
    let me=this;
    this.linkItem=new LinkItem();
    this.htmlCode=this.value;
    this.quill = new Quill('#'+this.randId, {
      modules: {
        //toolbar: '#'+this.randId+'tool',
        toolbar: [
          [{ header: [1,2,3,4, false] }],
          [{ 'color': [
              "var(--color-project)",""
            ] }],
          ['bold', 'italic',{ 'script': 'sub'} ],

          [
            //'link',
            'eelink'
          ],


          ['clean']

        ]
      },
      placeholder: '...',
      theme: 'snow'  // or 'bubble'
    });
    const delta = this.quill.clipboard.convert(this.value)
    this.quill.setContents(delta, 'silent')

    this.quill.on('text-change', function() {
      console.log(me.quill.root.innerHTML)
      me.htmlCode=me.quill.root.innerHTML;
      me.$emit("input",me.htmlCode);
    });

    let $linkBtn=document.querySelector('#container-'+this.randId+" .ql-eelink");
    $linkBtn.addEventListener("click",this.openLink);

  },
  methods:{
    openLink(){

      this.linkProps.blank=false;
      this.linkProps.url="";
      this.linkProps.text="";
      this.linkProps.uid="";

      this.range= this.quill.getSelection();
      let coreRange=window.getSelection().getRangeAt(0);
      this.linkProps.text = coreRange.cloneContents().textContent;

      let $temp=document.createElement("span");
      $temp.textContent=this.linkProps.text;
      $temp.id='temp'+Math.random();
      console.log("$temp.outerHTML",$temp.outerHTML)
      document.execCommand('insertHTML', false, $temp.outerHTML);
      $temp=document.getElementById($temp.id);
      console.log("$temp",$temp)
      if($temp){
        let $href=$temp.closest('a');
        if($href){
          this.linkProps.blank=$href.getAttribute("target")==="_blank"
          this.linkProps.url=$href.getAttribute("href")
        }
        console.log("$href",$href);
        console.log("$parent",$temp.parentElement);
        $temp.remove();
      }
      console.log("this.linkProps",this.linkProps)
      if(this.linkProps.url===""){
        let fmt=this.quill.getFormat(this.range);
        console.log("fmt",fmt)
        if(fmt.link){
          this.linkProps.url=fmt.link;
        }
      }

      document.execCommand('insertText', false, this.linkProps.text);

      //ouvre la popin
      this.choosingLink=true;


    },
    setLink(link){
      console.log("setLink",link);
      this.quill.setSelection(this.range);
      setTimeout(()=>{
        if(link){
          //let selection = window.getSelection().getRangeAt(0).cloneContents();
          //let $a = document.createElement('a');
          //a.appendChild(selection);
          let wrappedselection = `<a href="${link.url}" target="${link.blank?'_blank':''}">${link.text}</a>`;
          console.log("wrappedselection",wrappedselection);
          document.execCommand('insertHTML', false, wrappedselection);
        }else{

        }
        this.choosingLink=false;
      },100)

      /*
      window.getSelection().setRangeAt(0);

      */
    }
  }

}
</script>

<style lang="less">
.ql-eelink{
  width: auto !important;
  &:before{
    content: "Link";
    color: rgb(68,68,68);
  }
}
/*
.ql-snow{
.ql-formats{
  .ql-header{
    border: 1px solid pink;
    width: auto;
    font-size: 20px;
  }
}
}
*/
</style>