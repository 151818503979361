<template>
<div class="mb-4">
    <image-field label=""
                  :field="f.image"
                  :record-types="[]"
                  read-only
    >
      <v-btn
          v-if="link"
          icon @click="showLink=!showLink">
        <v-icon>{{$icons.link}}</v-icon>
      </v-btn>
    </image-field>

    <link-item-field
        v-if="link && showLink"
        :link-label="false"
        :field="f.link"/>

  <template v-if="texts">
    <field-label>Légende, crédits, etc...</field-label>
    <rich-text-field
        v-model="f.text"/>
  </template>

</div>
</template>

<script>
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import ImageField from "@/ee09/db-ui/fields/image-field";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import FieldLabel from "@/ee09/db-ui/fields/field-label";
export default {
  name: "image-item-field",
  mixins:[RecordFieldMixin],
  components: {
    FieldLabel,
    RichTextField,
    LinkItemField,
    ImageField,
  },
  data(){return{
    showLink:false
  }},
  props:{
    /**
     * Si true permettra d'éditer un lien sur l'image
     */
    link:{
      type:Boolean,
      default:false
    },
    /**
     * Si true permettra d'éditer un texte sur l'image
     */
    texts:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    /**
     * Le champ ImageItem
     * @return {ImageItem}
     */
    f(){
      return this.field;
    },
  }
}
</script>
