<template>
<div class="btn-block-edit">

  <link-item-field
      :field="obj.lien"
  />


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import AnchorForm from "@/Models/blocks/edit/anchor-form";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "btn-block-edit",
  components: {LinkItemField, AnchorForm},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {BtnBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
