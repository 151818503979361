<template>
<div>
  <list-items-organizer :list="obj.notifications">
    <list-items-organizer-item
        v-for="btn of obj.buttons.items"
        :list="obj.buttons"
        :item="btn"
        :key="btn.uid">
      <link-item-field :field="btn"/>
    </list-items-organizer-item>
  </list-items-organizer>

  <field-label>Texte à droite</field-label>
  <rich-text-field v-model="obj.text"/>
</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import FieldLabel from "@/ee09/db-ui/fields/field-label";

export default {
  name: "btns-text-block-edit",
  components: {FieldLabel, RichTextField, LinkItemField, ListItemsOrganizer, ListItemsOrganizerItem},
  mixins:[BlockMixin],
  mounted() {
    if(this.obj.buttons.items.length===0){
        this.obj.buttons.append();
    }
  },
  computed:{
    /**
     *
     * @return {BtnsTextBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
