<template>
  <v-row>
    <v-col cols="6">
      <v-switch
          v-model="block.isAnchor"
          label="Utiliser comme sous menu"
          hide-details
          dense
      />
    </v-col>
    <v-col cols="6" v-if="block.isAnchor">
      <v-text-field
          label="Texte sur le lien"
          v-model="block.anchorName"
          placeholder="Titre du sous menu"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "anchor-form",
  mixins:[BlockMixin],
}
</script>

<style scoped>

</style>