<template>
  <div>
    <field-label v-if="label" class="mb-2">{{label}}</field-label>
    <image-field
        :key="refreshSuggest"
        :field="tempImage"
        :uploader="false"
    >
      <template slot="action">
        <record-create-btn
            @mousedown.native.stop
            file-accept="image/*"
            multiple-uploads
            record-type="filerecord"
            @set-record-file="setFile"

        />
      </template>
    </image-field>

    <list-items-organizer
        :action-add="false"
        :list="fld">
      <list-items-organizer-item
          class="py-0"
          dense
          v-for="item of fld.items"
          :action-add="false"
          :list="fld"
          :item="item"
          :key="item.uid">
        <image-item-field
            :field="item"
            :link="fld.links"
            :texts="fld.texts"
        />
      </list-items-organizer-item>
    </list-items-organizer>

  </div>
</template>

<script>
import ImageField from "@/ee09/db-ui/fields/image-field";
import RecordCreateBtn from "@/ee09/db-ui/records/record-create-btn";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ImageItemField from "@/ee09/db-ui/fields/image-item-field";
import ImageFieldObject from "@/ee09/fields/ImageField";
import ImageItem from "@/ee09/models/ImageItem";
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import FieldLabel from "@/ee09/db-ui/fields/field-label";
export default {
  name: "image-item-list-field",
  mixins:[RecordFieldMixin],
  components: {
    FieldLabel,
    ImageItemField,
    ListItemsOrganizerItem,
    ListItemsOrganizer,
    RecordCreateBtn,
    ImageField
  },
  data(){
    return{
      refreshSuggest:0,
      tempImage:new ImageFieldObject()
    }
  },
  watch:{
    tempImageRecord(){
      if(this.tempImage.isOk){
        this.fld.prepend(new ImageItem(this.tempImage))
      }
      this.tempImage=new ImageFieldObject();
      this.refreshSuggest++;
    }
  },

  computed:{
    /**
     *
     * @return {ImageItemList}
     */
    fld(){
      return this.field
    },
    tempImageRecord() {
      return this.tempImage.record
    },
    /**
     *
     * @return {PhotoListBlock}
     */
    obj(){
      return this.block;
    }
  },
  methods:{
    /**
     *
     * @param {event} e
     * @param {FileRecord} record
     */
    setFile(e,record){
      let me = this;
      console.log("set file",record)
      if(record){
        let imageField=new ImageFieldObject();
        imageField.record=record;
        me.fld.prepend(
            new ImageItem(imageField)
        )
      }
    }
  }
}
</script>
