<template>
<div @mouseover="showPlus=true;" @mouseleave="showPlus=false">
  <v-card
      :id="`edit-${blck.uid}`"
      tile
      class="mb-5 ee09-admin-fade-in"
      elevation="2"
      :min-height="minHeight"

  >
    <v-system-bar
        v-if="allowEditStructure"
        dark height="50px">
      <v-icon>{{blck.blockModel.icon}}</v-icon>
      <span>{{blck.blockModel.label}}</span>

      <v-spacer></v-spacer>

      <v-btn icon  @click="fld.moveUp(blck);">
        <v-icon >{{ $icons.arrowUp }}</v-icon>
      </v-btn>
      <v-btn icon @click="fld.moveDown(blck);">
        <v-icon>{{ $icons.arrowDown }}</v-icon>
      </v-btn>
      <span class="mr-5"></span>
      <v-btn icon @click="fld.removeBlock(blck);">
        <v-icon>{{ $icons.trash }}</v-icon>
      </v-btn>
    </v-system-bar>

    <v-card-text>
      <component :is="blck.blockModel.editComponent" :block="blck"/>
    </v-card-text>
  </v-card>

  <add-block-buttons
      v-if="allowEditStructure"
      class="my-5"
      :field="field"
      :is-after-block="blck"/>
</div>
</template>

<script>
import AddBlockButtons from "@/ee09/db-ui/fields/blocks/add-block-buttons";
export default {
  name: "block-edit-box",
  components: {AddBlockButtons},
  data(){
    return{
      showPlus:false
    }
  },
  props:{
    field:{
      type:Object
    },
    block:{
      type:Object
    },/**
     * Permet de désactiver l' organisation et l' ajout de blocks
     **/
    allowEditStructure:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    /**
     *
     * @return {BlockItem}
     */
    blck(){
      return this.block;
    },
    /**
     *
     * @return {BlocksField}
     */
    fld(){
      return this.field;
    },
    minHeight(){
      if(this.disableEditStructure){
        return null
      }
      return "150";
    }
  }

}
</script>