<template>
<div>
  <v-switch v-model="obj.dense"
  label="Deux colonnes"
  />
  <records-field
      label="Films à afficher dans la liste"
      placeholder="Trouver un film à ajouter"
      :field="obj.records"
      :record-types="['pagefilm']"
  />
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RecordsField from "@/ee09/db-ui/fields/records-field";

export default {
  name: "films-block-edit",
  components: {RecordsField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {FilmsBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
