<template>
<div class="">
  <v-row>
    <v-col cols="2">
      <v-text-field
          filled
          label="préfixe"
          v-model="obj.prefix"
          hide-details
      />
    </v-col>
    <v-col cols="10">
      <v-text-field
          filled
          label="Titre"
          v-model="obj.title"
      />
    </v-col>
  </v-row>
  <media-text-block-edit
      :block="block"/>




</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import MediaTextBlockEdit from "@/Models/blocks/media/media-text-block-edit";

export default {
  name: "accordion-block-edit",
  components: {MediaTextBlockEdit},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {AccordionBlock}
     */
    obj(){
      return this.block;
    },
  }
}
</script>
