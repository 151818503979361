<template>
<div>
  <quill-editor  v-model="obj.text" />
  <br>
  <quill-editor  v-model="obj.text2" />
  <br>
  <anchor-form  :block="block"/>
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import QuillEditor from "@/ee09/db-ui/fields/quill-editor";
import AnchorForm from "@/Models/blocks/edit/anchor-form";

export default {
  name: "paragraph-block-edit",
  components: {AnchorForm, QuillEditor},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {ParagraphBlock}
     */
    obj(){
      return this.block;
    },
  },
}
</script>
