<template>
<div>
  <titre-block-edit :block="blc" :anchor="false"/>
  <v-row>
    <v-col>
      <v-switch
          dense
          v-model="obj.gridMode"
          label="Mode grille"
      />
    </v-col>
    <v-col>
      <v-switch
          dense
          v-model="obj.transparent"
          label="Transparent"
      />
    </v-col>
    <v-col cols="12"></v-col>
    <v-col cols="4">
      <v-select
          label="Type de liste"
          dense
          v-model="obj.listType"
          :items="[
            {
              text:'Manuelle',
              value:''
            },
            {
              text:'Tous les articles',
              value:'post'
            },
            {
              text:'Tous les portraits',
              value:'portrait'
            },
            {
              text:'Tous les projets',
              value:'projet'
            },
            {
              text:'Tous les métiers',
              value:'metier'
            }
        ]"
      />
    </v-col>
    <v-col cols="8" v-if="obj.listType !== ''">
      <records-field
          :placeholder="obj.listTypeRelatedFilter.records.length>0?'Formations associées':'Ne pas filtrer, tout afficher'"
          label="Filtrer par formations associées ?"
          :field="obj.listTypeRelatedFilter"
          :record-types="['formation']"/>
    </v-col>
  </v-row>




  <records-field
      v-if="obj.listType===''"
      label="Pages à afficher dans la liste"
      placeholder="Rechercher..."
      :field="obj.pages"
      :record-types="['page','post','formation','metier','projet','portrait']"
  />
</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import TitreBlockEdit from "@/Models/blocks/texte/titre-block-edit";
import RecordsField from "@/ee09/db-ui/fields/records-field";

export default {
  name: "carousel-pages-block-edit",
  components: {RecordsField, TitreBlockEdit},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CarouselPagesBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
