<template>
  <div class="code-preview">
    <v-alert v-if="$dbUi.preferences.debugMode"
             color="#222" dark tile
    >
      <h4 v-if="title" class="mb-5">{{title}}</h4>
      <div class="code-wrap">
        <pre v-if="code">{{code}}</pre>
        <pre v-else><slot></slot></pre>
      </div>

    </v-alert>
  </div>

</template>

<script>
export default {
name: "code-preview",
  props:["title","code"]
}
</script>

<style lang="less">
  .code-preview{
    position: relative;
    .v-alert__content{
      max-width: 100%;
      overflow-x: auto;
    }
    .code-wrap{

    }
  }
</style>