var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('titre-block-edit',{attrs:{"block":_vm.blc,"anchor":false}}),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"dense":"","label":"Mode grille"},model:{value:(_vm.obj.gridMode),callback:function ($$v) {_vm.$set(_vm.obj, "gridMode", $$v)},expression:"obj.gridMode"}})],1),_c('v-col',[_c('v-switch',{attrs:{"dense":"","label":"Transparent"},model:{value:(_vm.obj.transparent),callback:function ($$v) {_vm.$set(_vm.obj, "transparent", $$v)},expression:"obj.transparent"}})],1),_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Type de liste","dense":"","items":[
            {
              text:'Manuelle',
              value:''
            },
            {
              text:'Tous les articles',
              value:'post'
            },
            {
              text:'Tous les portraits',
              value:'portrait'
            },
            {
              text:'Tous les projets',
              value:'projet'
            },
            {
              text:'Tous les métiers',
              value:'metier'
            }
        ]},model:{value:(_vm.obj.listType),callback:function ($$v) {_vm.$set(_vm.obj, "listType", $$v)},expression:"obj.listType"}})],1),(_vm.obj.listType !== '')?_c('v-col',{attrs:{"cols":"8"}},[_c('records-field',{attrs:{"placeholder":_vm.obj.listTypeRelatedFilter.records.length>0?'Formations associées':'Ne pas filtrer, tout afficher',"label":"Filtrer par formations associées ?","field":_vm.obj.listTypeRelatedFilter,"record-types":['formation']}})],1):_vm._e()],1),(_vm.obj.listType==='')?_c('records-field',{attrs:{"label":"Pages à afficher dans la liste","placeholder":"Rechercher...","field":_vm.obj.pages,"record-types":['page','post','formation','metier','projet','portrait']}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }