<template>
<div>
   <link-item-field :field="obj.link"/>
</div>
</template>

<script>
import BlockMixin from "../blocks/BlockMixin";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "core-link-item-block-edit",
  components: {LinkItemField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CoreLinkItemBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
