<template>
  <div>
    <page-edit-fields :record="record">
      <template v-slot:meta>

        <v-textarea
            filled rows="3"
            label="Titre dans la page"
            placeholder="Titre de page"
            v-model="record.pagetitle"/>

        <v-text-field
            filled rows="3"
            label="Un film / clip  de..."
            placeholder="Un film de..."
            v-model="record.unfilmde"/>

        <v-textarea
            filled rows="3" auto-grow
            label="Avec..."
            placeholder="Avec..."
            hide-details
            v-model="record.casting"/>

        <image-field
            label="Affiche"
             :field="record.affiche"/>


      </template>

      <template v-slot:poster>
      </template>

      <template v-slot:blocks>
        <blocks-field
            label=""
            :field="record.blocks"/>
      </template>



    </page-edit-fields>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import ImageField from "@/ee09/db-ui/fields/image-field";


export default {
  name: "pagefilm-edit",
  components: {
    ImageField,
    PageEditFields,
    BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  }
}
</script>

