<template>
  <div>

    <page-edit-fields :record="record"
      :labels="{
        name:{
          label:'Titre',
          placeholder:'Mon super article'
        }
      }
      "
    >
      <template slot="meta">
        <records-field
            placeholder="Formations associées"
            label=""
            :field="rec.relateds"
            :record-types="['formation']"/>
        <v-text-field
            filled
            label="Sous titre"
            v-model="rec.baseline"
            placeholder="Ici complétez le titre de l'article"
        />


      </template>

      <template slot="blocks">
        <blocks-field
            label=""
            :field="rec.blocks"/>
      </template>

    </page-edit-fields>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />


  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import RecordsField from "@/ee09/db-ui/fields/records-field";


export default {
  name: "post-edit",
  components: {RecordsField, PageEditFields, BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){return {}},
  props:{},
  computed:{
    /**
     *
     * @return {PostModel}
     */
    rec(){
      return this.record;
    }
  }
}
</script>

