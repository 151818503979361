<template>
  <list-items-organizer :list="obj.list">
    <list-items-organizer-item
        v-for="link of obj.list.items"
        :list="obj.list"
        :item="link"
        :key="link.uid">
      <link-item-field :field="link"/>
    </list-items-organizer-item>
  </list-items-organizer>
</template>

<script>
import BlockMixin from "../blocks/BlockMixin";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";

export default {
  name: "core-link-item-list-block-edit",
  components: {ListItemsOrganizer, ListItemsOrganizerItem, LinkItemField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CoreLinkItemListBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
