<template>
  <div class="rich-text-field">
    <div v-if="editor" class="mb-2 d-flex">
      <v-btn  text icon tile
              title="Titre"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        <v-icon>{{$icons.formatTitle}}</v-icon>
      </v-btn>
      <v-btn  text icon tile
              title="Gras"
              @click="editor.chain().focus().toggleBold().run()"
              :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon>{{$icons.formatBold}}</v-icon>
      </v-btn>
      <v-btn text icon tile
             title="Italique"
              @click="editor.chain().focus().toggleItalic().run()"
              :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon>{{$icons.formatItalic}}</v-icon>
      </v-btn>
      <v-btn text icon tile
              @click="setLink"
             title="Lien"
              :class="{ 'is-active': editor.isActive('link') }">
        <v-icon>{{$icons.link}}</v-icon>
      </v-btn>
      <v-btn text icon tile
             title="Liste"
             @click="editor.chain().focus().toggleBulletList().run()"
             :class="{ 'is-active': editor.isActive('bulletList') }">
        <v-icon>{{$icons.formatBullet}}</v-icon>
      </v-btn>
      <v-btn text icon tile
             title="Saut de ligne"
             @click="editor.chain().focus().setHardBreak().run()">
        <v-icon>{{$icons.formatBreakLine}}</v-icon>
      </v-btn>

      <!--
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        strike
      </button>
      <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </button>
      -->
      <v-btn  text icon tile
              title="Effacer la mise en forme"
              @click="editor.chain().focus().unsetAllMarks().clearNodes().run();">
        <v-icon>{{$icons.formatClear}}</v-icon>
      </v-btn>

      <v-spacer/>
      <!-- edit code source-->
      <v-btn text icon tile
             title="Code HTML"
             :class="{ 'is-active': htmlEdit }"
             @click="htmlEdit=!htmlEdit">
        <v-icon>{{$icons.fileCodeHtml}}</v-icon>
      </v-btn>

      <!--
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>

      <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        h1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        h2
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        h4
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </button>


      <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        ordered list
      </button>
      <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        code block
      </button>
      -->
      <!--
      <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>

      <button @click="editor.chain().focus().undo().run()">
        undo
      </button>
      <button @click="editor.chain().focus().redo().run()">
        redo
      </button>
      -->
    </div>

    <v-textarea
        v-if="htmlEdit"
        background-color="#222" dark
        auto-grow
        :rounded="0"
        filled outlined

        style="font-family: monospace;"
        v-model="htmlCode"
        @input="$emit('input', htmlCode)"
    />

    <editor-content class="editor-box mb-5" :editor="editor"/>
    <v-dialog v-model="linkModal" max-width="500px">
      <v-card>
        <v-form @submit.prevent="applyLink">
          <v-system-bar>
            <v-spacer/>
            <v-icon size="16" @click="linkModal=false">{{$icons.close}}</v-icon>
          </v-system-bar>
          <v-card-text>
            <v-text-field label="url" v-model="url"/>
            <record-field
                :field="searchRecord"
                :record-types="[]"
                label="Rechercher une page du site"/>
            <v-switch v-model="blank" label="ouvrir dans une nouvelle fenêtre"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="linkModal=false;">Annuler</v-btn>
            <v-btn text type="submit" @click="linkModal=false;applyLink">Appliquer</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>


<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link'
import RecordFieldModel from "@/ee09/fields/RecordField";
import RecordField from "@/ee09/db-ui/fields/record-field";
export default {
  components: {
    RecordField,
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      htmlEdit:false,
      htmlCode:'',
      editor: null,
      linkModal:false,
      /**
       *@type {RecordField}
       **/
      searchRecord:null,
      url:"",
      blank:false
    }
  },
  computed:{
    /**
     *@type {PageModel}
     **/
    searchRecordResult(){
      if(this.searchRecord){
        return this.searchRecord.record
      }
      return null;
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value
      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()
      if (isSame) {
        return
      }
      this.editor.commands.setContent(this.value, false);
      this.htmlCode=this.value;
    },
    searchRecordResult(){
      let me=this;
      if(this.searchRecordResult){
        this.url=this.searchRecordResult.href;
        setTimeout(function(){
          me.searchRecord.record=null;
        },200)

      }
    }
  },
  mounted() {
    this.searchRecord=new RecordFieldModel();
    this.editor = new Editor(

        {

      extensions: [
        Link.configure({
          openOnClick:false,
          HTMLAttributes:false
        }),
        StarterKit
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.htmlCode=this.editor.getHTML()
        this.$emit('input',this.htmlCode );
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    }
    );
    this.htmlCode=this.value;
  },
  methods:{
    setLink() {
      let sel=window.getSelection()
      this.url="";
      this.blank=false;
      if(sel){
        let $link=sel.getRangeAt(0).startContainer.parentNode;
        if($link.matches("[href]")){
          this.url=$link.getAttribute("href")
          if($link.matches("[target='_blank']")){
            this.blank=true;
          }else{
            this.blank=false;
          }
        }
      }
      this.linkModal=true;
    },
    applyLink(){
      if(this.url){
        this.editor.chain().focus().setLink({
          href: this.url ,
          target:this.blank?"_blank":"_self",
        }).run();
      }else{
        this.editor.chain().focus().unsetLink().run();
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
};
</script>
<style lang="less">
.rich-text-field{
  .editor-box> * {
    background-color: #F0F0F0;
    padding: 15px;
    max-height: 80vh;
    overflow-y: auto;
    color: #000;
  }

  .is-active{
    color: #000 !important;
    background-color: #E0E0E0 !important;
  }
  /* *:focus {
     outline: none;
  }  */
}

</style>