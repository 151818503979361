<template>
<div class="titre-block-edit">
  <v-text-field
    label="Titre"
    v-model="obj.text"
    placeholder="Saisissez un titre"
  />
  <v-row>
    <v-col>
      <v-select
          label="Style de titre"
          v-model="obj.cssClass"
          :items="[
          //{text:'Titre 1',value:'h1'},
          //{text:'Titre 2',value:'h2'},
          {text:'Grand',value:'h3'},
          {text:'Petit',value:'h4'},
      ]"
      ></v-select>
      <v-switch
          v-model="obj.center"
          label="Centrer"
          dense hide-details
      />
    </v-col>
    <v-col>
      <v-select
          label="Balise pour le SEO"
          v-model="obj.seo" hide-details
          :items="['h1','h2','h3','h4','div']"
      />
    </v-col>
  </v-row>

  <anchor-form v-if="anchor" :block="block"/>


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import AnchorForm from "@/Models/blocks/edit/anchor-form";

export default {
  name: "titre-block-edit",
  components: {AnchorForm},
  mixins:[BlockMixin],
  props:{
    anchor:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    /**
     *
     * @return {TitreBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
