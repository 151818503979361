<template>
<div>
  <v-switch v-model="obj.dense"
  label="Deux colonnes"
  />
  <records-field
      label="Locations à afficher dans la liste"
      placeholder="Trouver une location à ajouter"
      :field="obj.records"
      :record-types="['pagelocation']"
  />
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RecordsField from "@/ee09/db-ui/fields/records-field";

export default {
  name: "locations-block-edit",
  components: {RecordsField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {LocationsBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
