<template>
<div>
  <image-field
      :key="refreshSuggest"
      :field="tempImage"
      label="Rechechez et ajoutez des images"
      :uploader="false"
  >
    <template slot="action">
      <record-create-btn
          @mousedown.native.stop
          file-accept="image/*"
          multiple-uploads
          record-type="filerecord"
          @set-record-file="setFile"

      />
    </template>
  </image-field>

  <list-items-organizer
      :action-add="false"
      :list="obj.images">
    <list-items-organizer-item
        class="py-0"
        v-for="item of obj.images.items"
        :action-add="false"
        :list="obj.images"
        :item="item"
        :key="item.uid">
      <image-item-field
          :field="item"
          :link="obj.links"
          :texts="obj.texts"
      />
    </list-items-organizer-item>
  </list-items-organizer>

</div>
</template>

<script>
import BlockMixin from "../../BlockMixin";
import ImageField from "@/ee09/db-ui/fields/image-field";
import ImageFieldObject from "@/ee09/fields/ImageField.js";
import ListItemsOrganizer from "@/ee09/db-ui/fields/list-items-organizer";
import ListItemsOrganizerItem from "@/ee09/db-ui/fields/list-items-organizer-item";
import ImageItem from "@/ee09/models/ImageItem";
import ImageItemField from "@/ee09/db-ui/fields/image-item-field";
import RecordCreateBtn from "@/ee09/db-ui/records/record-create-btn";

export default {
  name: "photo-list-block-edit",
  mixins:[BlockMixin],
  components: {
    RecordCreateBtn,
    ImageItemField,
    ListItemsOrganizerItem,
    ListItemsOrganizer,
    ImageField,
  },
  data(){
    return{
      refreshSuggest:0,
      tempImage:new ImageFieldObject()
    }
  },
  watch:{
    tempImageRecord(){
      if(this.tempImage.isOk){
        this.obj.images.prepend(new ImageItem(this.tempImage))
      }
      this.tempImage=new ImageFieldObject();
      this.refreshSuggest++;
    }
  },

  computed:{
    tempImageRecord() {
      return this.tempImage.record
    },
    /**
     *
     * @return {PhotoListBlock}
     */
    obj(){
      return this.block;
    }
  },
  methods:{
    /**
     *
     * @param {event} e
     * @param {FileRecord} record
     */
    setFile(e,record){
      let me = this;
      console.log("set file",record)
      if(record){
        let imageField=new ImageFieldObject();
        imageField.record=record;
        me.obj.images.prepend(
            new ImageItem(imageField)
        )
      }
    }
  }
}
</script>
