<template>
<div>
  <quill-editor v-model="obj.text"/>
    <!--rich-text-field v-model="obj.text" /-->
</div>
</template>

<script>
import BlockMixin from "../blocks/BlockMixin";
import RichTextField from "@/ee09/db-ui/fields/rich-text-field";
import QuillEditor from "@/ee09/db-ui/fields/quill-editor";

export default {
  name: "core-string-html-block-edit",
  components: {QuillEditor, RichTextField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CoreStringBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
