<template>
  <div>
    <v-alert
        v-if="pageUrl.errors.length"
        color="error" dark dense tile elevation="3">
      <div v-for="(err,i) of pageUrl.errors" :key="i">{{err}}</div>
    </v-alert>
    <v-text-field
        label="Title"
        filled
        v-model="pageUrl.title"
        counter
        :rules="[value => !!value || 'Pas de titre']"
    />
    <v-text-field
        v-if="!pageUrl.ishome"
        class="mb-5"
        label="url"
        filled
        v-model="pageUrl.cleanSlug"
        :hint="pageUrl.slug" persistent-hint
    />
    <v-textarea
        label="Description"
        :rules="[value => !!value || 'Pas de description']"
        v-model="pageUrl.description"
        filled
        auto-grow
        rows="1"
        counter
    />
    <v-switch
        v-if="!pageUrl.ishome"
        dense
        v-model="pageUrl.hideid"
        label="Masquer l'identifiant"
    />
    <v-slider
        v-if="!pageUrl.ishome"
        min="0" max="1" step="0.1"
        v-model="pageUrl.priority"
        label="Priorité"
              >
      <template v-slot:append>
      <v-text-field
          v-model="pageUrl.priority"
          class="mt-0 pt-0"
          max="1" min="0"
          step="0.1"
          hide-details
          single-line
          type="number"
          style="width: 40px"
      />
    </template>
    </v-slider>

    <v-select
        label="Fréquence de mise à jour"
      v-model="pageUrl.changefreq"
      :items="freqItems"
    ></v-select>

    <!--
    <v-text-field
        label="Lien canonique"
        v-model="pageUrl.canonical"
        dense
    />
    -->
    <!--
    <v-textarea
        label="Données structurées (JSON LD)"
        hide-details
        v-model="pageUrl.jsonld"
        filled
        auto-grow
        rows="1"
        style="font-family: monospace;font-size: 11px;"
    />
    -->
    <!-- HOME PAGE ??? -->
    <v-menu close-on-click v-if="$db.userAdminDev && !pageUrl.ishome">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            class="my-5" small color=""
            v-bind="attrs" v-on="on">
          <v-icon left >{{$icons.checkBoxBlank}}</v-icon>
          home page du site ?
        </v-btn>
      </template>
      <v-card dark>
        <v-card-title>
          <h4 class="subtitle-2">Êtes vous certain ?</h4>
        </v-card-title>
        <v-card-text>

          <p>Si vous répondez OUI, cette page deviendra la home page du site !</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn small color="error" @click="pageUrl.ishome=true">Oui</v-btn>
          <v-btn small text>annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <field-label class="mt-5" v-if="pageUrl.recordPage.imagesFromContent.length">SEO des images</field-label>
    <image-field
        v-for="(img,i) of pageUrl.recordPage.imagesFromContent"
        :key="`${img.uid}${i}`"
        :field="img"
        :open="img.errors.length>0"
    />

  </div>
</template>

<script>

import PageUrl from "@/Models/PageUrl";
import ImageField from "@/ee09/db-ui/fields/image-field";
import FieldLabel from "@/ee09/db-ui/fields/field-label";


export default {
  name: "page-url-fields",
  components: {FieldLabel, ImageField},
  data(){
    return {
      freqItems:[
        {
          text: "Jamais",
          value: PageUrl.CHANGE_FREQ_NEVER
        },
        {
          text: "Tous les ans",
          value: PageUrl.CHANGE_FREQ_YEARLY
        },
        {
          text: "Tous les mois",
          value: PageUrl.CHANGE_FREQ_MONTHLY
        },
        {
          text: "Toutes les semaines",
          value: PageUrl.CHANGE_FREQ_WEEKLY
        },
        {
          text: "Tous les jours",
          value: PageUrl.CHANGE_FREQ_DAILY
        },
        {
          text: "Toutes les heures",
          value: PageUrl.CHANGE_FREQ_HOURLY
        },
      ]
    }
  },
  mounted(){
    this.isHome=this.pageUrl.ishome
  },
  props:{
    pageUrl:{
      type:PageUrl,
      validator: function () {
        return true; // or false based on value of myImage
      }
    }
  },computed:{
    /**
     *
     * @return {PageurlModel}
     */
    recordObject(){
      return this.record;
    },
  },
}
</script>

